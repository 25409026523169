.Wrapper p {
  display: flex;
  align-items: center;
  gap: 20px;
}
.Wrapper p span {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

@media screen and (max-width: 500px) {
  .Wrapper p span {
    font-size: 18px;
    width: 100% !important;
  }
}
