.ant-tabs-tab-btn {
  color: rgb(0, 0, 0) !important;
  padding: 10px 25px !important;
  border-radius: 20px !important;
  background-color: white !important;
  border: 1px solid white !important;
  letter-spacing: 0.01em !important;
  font-weight: 600 !important;
  font-size: 20px;
}
:where(.css-dev-only-do-not-override-ed5zg0).ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-ink-bar {
  background: black !important;
}

.ant-tabs-content-holder {
  display: none !important;
}
