.WrapperBack {
  background-image: url(../../../assets/HomeHero/back.png);
  background-repeat: no-repeat;
  background-size: 100%, 100% !important;
}
.Wrapper {
  text-align: center;
  color: white;
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}
.Wrapper2 {
  width: 100%;
  height: 23vh;
}

.Wrapper p {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  width: 100%;
}
.Wrapper h2 {
  font-size: 100px;
  margin: 0;
  background-color: #0b3d2c3b !important;
  padding-bottom: 1%;
  width: 100%;
}
.Wrapper .subtext {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0b3d2c3b !important;
  gap: 30px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
}
.subtextP {
  background-color: #0b3d2c3b !important;
  padding-bottom: 20px;
}
.subtext div {
  background-color: white;
  width: 70px;
  height: 2px;
}
.Buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 3% 0 2%;
  width: 100%;
  margin: 0 auto;
  background-color: #0b3d2c3b !important;
}
.Buttons a button {
  color: white;
  background-color: transparent;
  padding: 15px 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  font-style: normal !important;
  border-radius: 20px;
  border: 2px solid white;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .Wrapper2 {
    height: 13vh !important;
  }
  .Wrapper {
    height: 75.5vh !important;
  }
  .Wrapper h2 {
    font-size: 70px;
  }
  .WrapperBack {
    background-size: cover !important;
    background-position: center;
    padding: 7% 0 0;
  }
}
@media screen and (max-width: 570px) {
  .Wrapper h2 {
    font-size: 35px !important;
  }
  .Wrapper p {
    font-size: 15px;
  }
  .subtext {
    gap: 10px !important;
  }
}

@media screen and (max-width: 500px) {
  .Wrapper2 {
    height: 12.5vh !important;
  }
}

@media screen and (max-width: 370px) {
  .Buttons button {
    font-size: 13px !important;
    padding: 10px 14px !important;
  }
  .subtext div {
    width: 30px;
  }
}
