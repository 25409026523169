.slider .slick-list {
  padding: 5% 0% !important;
  width: 100%;
  margin: 0 auto;
  background-color: transparent !important;
}

.slider {
  width: 95% !important;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .slider {
    width: 90% !important;
  }
}
@media screen and (max-width: 400px) {
  .slider {
    width: 85% !important;
  }
}
