.WrapperBack {
  background-color: #124a2f;
  padding: 3% 0;
}
.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 0% 2%;
}
.imgDiv img {
  width: 100%;
  border-radius: 20px;
}
.texts {
  width: 100% !important;
}
.texts h2 {
  color: white;
  font-family: "Montserrat";
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.texts p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: white;
}
.texts button {
  background-color: #f1d2a9;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  color: black;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #f1d2a9;
}

@media screen and (max-width: 1120px) {
  .Wrapper h2 {
    font-size: 35px;
  }
}
@media screen and (max-width: 940px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
}

@media screen and (max-width: 500px) {
  .Wrapper h2 {
    font-size: 30px;
  }
}
