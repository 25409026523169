.Wrapper {
  width: 100%;
  height: 23vh;
}
.WrapperMain {
  display: flex;
  align-items: flex-end;
  justify-content: center flex-end;
  height: 115vh !important;
}
.Main {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100% !important;
  height: 70% !important;
}

.texts h2 {
  font-family: "Montserrat";
  font-size: 65px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: white;
  text-align: center;
}
.buttons {
  background: #0b3d2cd9;
  width: 50%;
  margin: 0 auto !important;
  border-start-end-radius: 20px;
  border-start-start-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: white;
}
.buttons p {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0em;
}
.meida {
  display: none !important;
}
@media screen and (max-width: 940px) {
  .Wrapper ul {
    display: none;
  }
  .Wrapper ion-icon {
    display: block;
  }
  .Wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  .icon {
    background-color: #f1d2a9;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .Wrapper ion-icon {
    color: #0b3d2c;
    font-size: 25px;
    font-weight: bold;
  }
}
@media screen and (max-width: 1000px) {
  .Wrapper {
    height: 15vh;
  }
  .WrapperMain {
    height: 87.5vh !important;
  }
  .Main {
    height: 65% !important;
  }
}

@media screen and (max-width: 610px) {
  .buttons {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .texts h2 {
    font-size: 50px;
  }
  .buttons p {
    font-size: 15px;
  }
  .Wrapper {
    height: 12.5vh !important;
  }
}
