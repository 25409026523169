.Ul {
  width: 100% !important;
  display: flex;
  gap: 20px;
  margin-top: 4%;
}

.li {
  width: 100%;
  margin-bottom: 3% !important;
}
.li ion-icon {
  color: #0b3d2c;
  font-size: 25px;
}
.li p {
  font-size: 16px;
}
.li a {
  text-decoration: none;
}
.li h3 {
  color: #0b3d2c;
  font-size: 20px;
  font-weight: 600;
}

.icon {
  background-color: #f1d2a9;
  border-radius: 50px;
  width: 45px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.texts {
  margin-left: 2%;
  width: 100%;
}

@media screen and (max-width: 555px) {
  .Ul {
    display: flex;
    flex-direction: column;
    gap: 3% !important;
  }
}
