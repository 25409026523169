.WrapperBack {
  background-color: white;
}

.Wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  padding: 2% 2%;
}
.Wrapper .Ul {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  margin-left: auto;
  gap: 50px;
}
.Wrapper p,
h3 {
  margin: 0;
}
.Wrapper a {
  text-decoration: none;
}
.Wrapper .Ul .li {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Wrapper .Ul .li:nth-child(1) {
  width: 500px !important;
}
.Wrapper ion-icon {
  color: #0b3d2c;
  width: 30px;
  height: 20px;
}
.Wrapper h3 {
  color: #0b3d2c;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

.icon {
  background-color: #f1d2a9;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icons {
  background-color: #f1d2a9;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.texts {
  margin-left: 5%;
  display: flex;
  flex-direction: column !important;
  align-items: flex-end !important;
}

.Wrapper .li:nth-child(2) {
  width: 30% !important;
}

.Wrapper .li:nth-child(3) {
  width: 30% !important;
}
.Logo {
  display: none;
}

.iconsM {
  display: none;
}
.media {
  display: none;
}

@media screen and (max-width: 1340px) {
  .Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Wrapper .Ul {
    overflow: scroll !important;
  }
  .Wrapper .li {
    width: 100% !important ;
  }

  .Logo {
    display: block;
    border-radius: 100px;
    background-color: #2a7d2e;
    padding: 2%;
    width: 30%;
    height: 11%;
  }

  .Logo img {
    width: 100% !important;
  }
}
@media screen and (max-width: 1000px) {
  .Ul {
    display: none !important;
  }
}
@media screen and (max-width: 1140px) {
  .iconsM {
    display: block;
    background-color: #f1d2a9;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@media screen and (max-width: 660px) {
  .Logo {
    width: 50%;
    height: 20%;
  }
}

@media screen and (max-width: 500px) {
  .Wrapper {
    display: none;
  }
  .img2 img {
    width: 250px !important;
  }
  .icon ion-icon {
    font-size: 25px !important;
  }
}

.WrapperBack2 {
  background-image: url(../../../assets/png.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}
.WrapperBack2 img {
  width: 340px;
}
.Wrapper2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 13vh;
  width: 100% !important;
}
.Wrapper2 ul {
  display: flex;
  align-items: center;
  gap: 50px;
}
.Wrapper2 ul li a {
  color: white;
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}
.Wrapper2 ul select {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 19px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: transparent;
}
.Wrapper2 ul select option {
  font: 500 17px tahoma;
  background-color: #0b3d2c;
}

@media screen and (max-width: 1000px) {
  .Wrapper2 ul {
    display: none;
  }
  .Wrapper2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96% !important;
    padding: 1% 2%;

  }
  .media {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 500px) {
  .Wrapper2 {
    height: 12.5vh !important;
  }
}
