.Wrapper {
  padding: 5% 2%;
  text-align: center;
}
.p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Wrapper h2 {
  text-align: center;
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 3% 0;
}
.cards img {
  width: 100% !important;
  border-radius: 20px;
  height: 100%;
}
.card {
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.65s;
  padding-bottom: 3%;
}
.card:hover .texts {
  background-color: white;
}
.card:hover {
  box-shadow: 0px 10px 60px 0px #0000001a;
  transform: scale(1.05);
}
.imgDiv {
  position: relative;
  width: 100%;
  height: 400px;
}

.icon {
  box-shadow: 0px 10px 60px 0px #0b3d2c80;
  position: absolute;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  top: 92%;
  left: 45%;
  cursor: pointer;
  transition: 0.65s;
}
.icon:hover {
  background-color: #2a7d2e;
  box-shadow: 0px 10px 60px rgba(11, 61, 44, 0.85);
  color: white;
}
.icon ion-icon {
  font-size: 25px;
}
.texts {
  padding: 10% 0;
  transition: 0.65s;
}
.texts h3 {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #0b3d2c;
}
.texts p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #2f2f2f;
}
.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  overflow: hidden;
  height: 0px !important;
  transition: 0.65s !important;
}
.card:hover .icons {
  height: 70px !important;
}
.icons ion-icon {
  font-size: 25px;
  border: 1px solid #0b3d2c1a;
  border-radius: 50%;
  color: #0b3d2c;
  padding: 3%;
  transition: 0.65s;
}
.icons ion-icon:hover {
  background-color: #0b3d2c;
  color: white;
}

@media screen and (max-width: 1050px) {
  .icon {
    left: 40%;
  }
}

@media screen and (max-width: 950px) {
  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
  .icon {
    left: 45% !important;
  }
}
@media screen and (max-width: 500px) {
  .card:hover {
    transform: scale(1.03) !important;
  }
  .icon {
    left: 43% !important;
  }
  .Wrapper h2 {
    font-size: 30px;
  }
}
