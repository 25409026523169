.Wrapper {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 5% 2%;
}
.imgDiv img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.imgDiv {
  width: 50%;
}
.texts {
  width: 50%;
  padding: 4% 4%;
  color: #0b3d2c !important;
  color: white;
}
.texts h2 {
  font-family: "Montserrat";
  font-size: 35px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.subtext {
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0em;
}
.card {
  box-shadow: 0px 0px 30px 0px #2a7d2e6a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 0% 3%;
  border-radius: 20px;
}
.texts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.card img {
  width: 60px;
  height: 60px;
  color: white !important;
}
.texts h3 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.card p {
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  width: 90%;
}

@media screen and (max-width: 1320px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .texts {
    width: 90%;
  }
  .imgDiv {
    width: 600px;
    height: 600px;
  }
  .imgDiv img {
    width: 100% !important;
  }
}
@media screen and (max-width: 700px) {
  .card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 3%;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .imgDiv {
    width: 90% !important;
    height: auto !important;
  }
}
@media screen and (max-width: 570px) {
  .Wrapper h2 {
    font-size: 25px;
  }
  .Wrapper p {
    margin: 0 !important;
  }
}
