.WrapperBack {
  background-image: url(../../assets/footer/back.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 13% 2% 5%;
}
.WrapperBack2 {
  /* background-image: url(../../assets/footer/se.png); */
  background-color: #0B3D2C;
  background-size: cover !important;
  background-repeat: no-repeat;
}
.Wrapper {
  background-color: #0b3d2c6c !important;
  padding: 3% 0;
  /* width: 90%; */
  /* margin: 0 auto; */

}
.Wrapper a {
  color: white;
  text-decoration: none;
  width: 100%;
}
.subtext {
  font-size: 19px;
  font-size: 500;
}
.Wrapper ul {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin: 0;
  padding: 0;
  color: white;
}
.headNumbers {
  margin: 4% 0 3%;
}
.numbers {
  margin: 0 0 2%;
}
.imgs {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 5%;
}
.imgs img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.img img {
  width: 300px;
}
.img {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.Wrapper ul li {
  /* width: 33% !important; */
  margin: 0 auto;
  /* border: 1px solid red; */
}
.Wrapper ul li:nth-child(1) {
  width: 50%;
}
.Wrapper ul li:nth-child(2) {
  width: 17% !important;
  /* border: 1px solid red; */
}
.Wrapper p {
  font-weight: 500;
}
.Wrapper .subtext {
  width: 80%;
  font-weight: 500;
}
.icons {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 25px;
}
.icon {
  border: 2px solid #ffffff1a;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.65s;
  cursor: pointer;
}
.icon:hover {
  background-color: #2a7d2e;
}
.Wrapper ul li h3 {
  border-bottom: 2px solid #f1d2a9;
  padding-bottom: 5%;
}
.Wrapper ul li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 2.5% 2%;
  position: relative;
  border-top: 1px solid #f1d2a9;
}
.Bottom ul {
  display: flex;
  align-items: center;
  gap: 50px;
}

.icona {
  position: absolute;
  top: -35%;
  left: 47.5%;
  background-color: white;
  color: black;
  cursor: pointer;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.icona a {
  background-color: #f1d2a9;
  color: black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

@media screen and (max-width: 1250px) {
  .Wrapper {
    padding: 20% 2% 10%;
  }
  .icona {
    top: -40%;
    left: 40%;
  }
  .Wrapper ul li:nth-child(2) {
    width: 100% !important;
    /* border: 1px solid red; */
  }
}
@media screen and (max-width: 900px) {
  .Wrapper ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    width: 100%;
  }
  .Wrapper ul li {
    width: 100% !important;
  }
  .Bottom ul {
    gap: 10px;
  }
}

@media screen and (max-width: 750px) {
  .Bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 500px) {
  .img img {
    width: 250px;
  }
  .Bottom {
    padding: 5%;
  }
  .Bottom p {
    padding: 7% 0 5%;
    text-align: center;
    margin: 0 auto;
  }
}
