.Wrapper {
  text-align: center;
}
.Wrapper h2 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.02em;
  margin-bottom: 3%;
}
.card {
  background-color: #fcfaf7;
  padding: 7% 0;
  border-radius: 20px;
  margin-top: 5%;
}
.card img {
  width: 80%;
  height: 350px;
  border-radius: 20px;
  padding: 3%;
  margin: 0 auto;
}
.card .text {
  padding: 4%;
}
.col {
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .Wrapper h2 {
    font-size: 30px;
  }

}
