.Wrapper {
  padding: 3% 0% 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
}
.Wrapper h2 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.card {
  border: 1px solid white;
  background-color: white;
  border-radius: 20px;
  padding: 10%;
  width: 70% !important;
  margin: 0 auto;
  box-shadow: 0px 7px 60px 0px #00000012;
}
.user img {
  width: 50px;
  border-radius: 50%;
}
.card .headerCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerCard h3 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.icons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5%;
  color: #ff9900;
}
.icons i {
  font-size: 20px;
}
.card .subtext {
  color: #0b3d2c;
  font-size: 16px;
  margin: 8% 0;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 160%;
}
.card .user {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border: 1px solid grey;
  border-radius: 20px;
  padding: 3% 0%;
}
.user p {
  margin: 0;
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}
.user h3 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 500px) {
  .Wrapper h2 {
    font-size: 25px;
  }
  .Wrapper {
  padding: 0 2%;
  }
  .logos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
  .Wrapper {
    border: none;
  }
  .card {
    margin: 0 auto !important;
  }
  .headerCard img {
    width: 30px;
    height: 20px;
  }
}
