.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid grey;
  padding: 3% 0%;
  margin: 2% 0 0  ;
}
.Wrapper img {
  max-width: 200px;
  width: 100% !important;
  height: 100% !important;
}
.Wrapper img:nth-child(7) {
  max-width: 100px !important;
  width: 100% !important;
  height: 100% !important;
}

@media screen and (max-width: 500px) {
  .Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 5% 2% 0;
  }
  .Wrapper img {
    width: 250px !important;
  }
}
