.Wrapper {
  padding: 3% 2%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
}
.texts {
  width: 50%;
}
.mainImg {
  border-radius: 20px;
  width: 400px;
  height: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniImgs {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  gap: 20px;
  margin-top: 3%;
}
.miniImgs img {
  width: 200px !important;
  height: 150px;
  border-radius: 20px;
}

.texts h2 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.rating {
  display: flex;
  align-items: center;
  padding: 3% 0;
  gap: 10px;
}
.rating p {
  margin: 0;
  color: #2f2f2f;
  font-weight: 500;
}
.icons {
  display: flex;
  align-items: center;
  gap: 5px;
}
.icons i {
  color: #f7921e;
}

.subtext {
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}
.texts ul li h3 {
  font-weight: 500;
}
.connecting {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 3% 0;
}
.icon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.icon a {
  background-color: #2a7d2e1a;
  color: #2a7d2e;
  padding: 5%;
  font-size: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.65s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.icon i:hover {
  background-color: #2a7d2e;
  color: white;
}

.connecting h3 {
  font-weight: 600;
}
.dec {
  padding: 0 2%;
}
.dec .mainh3 {
  font-family: "Montserrat";
  font-size: 30px;
  color: #0b3d2c;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.dec ul li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dec ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dec ul li i {
  background-color: #2a7d2e;
  margin: 0;
  padding: 0;
  width: 25px;
  height: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
}
.dec h3 {
  font-weight: 600;
}

@media screen and (max-width: 1140px) {
  .mainImg {
    width: 60%;
    height: 450px;
  }
  .texts {
    width: 70% !important   ;
  }
  .texts h2 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 1030px) {
  .miniImgs img {
    height: 100px !important;
  }
  .mainImg {
    width: 300px !important;
    height: 300px;
  }
  .images {
    width: 70%;
  }
}

@media screen and (max-width: 720px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .texts {
    width: 100% !important;
  }
  .images {
    width: 85%;
  }
}

@media screen and (max-width: 500px) {
  .miniImgs img {
    width: 100% !important;
  }
}
