.cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.Wrapper {
  padding: 2%;
}
.mainImg {
  border-start-start-radius: 20px;
  border-start-end-radius: 20px;
  width: 100%;
  height: 300px;
}
.texts {
  position: relative;
}
.card {
  cursor: pointer;
  transition: 0.65s;
  border-radius: 20px;
  width: 300px;
  background-color: #fcfaf7;
}
.card:hover .texts {
  background-color: #2a7d2e;
  color: white;
}
.card:hover {
  background-color: #2a7d2e;
  transform: scale(1.1);
}
.IconImg {
  background-color: #2a7d2e;
  border-radius: 50%;
  box-shadow: 0px 10px 60px rgba(42, 125, 46, 0.4);
  padding: 5%;
  position: absolute;
  left: 8%;
  top: -28%;
}
.texts {
  background-color: #fcfaf7;
  padding: 20% 4% 10% 4%;
  border-end-end-radius: 20px;
  border-end-start-radius: 20px;
  transition: 0.65s;
}
@media screen and (max-width: 1200px) {
  .cards {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
}
@media screen and (max-width: 970px) {
  .card:hover {
    transform: scale(1.03) !important;
  }
}
@media screen and (max-width: 500px) {
  .card {
    width: 90% !important;
  }
}
