.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #0b3d2c1a;
  padding: 5% 2%;
}
.Wrapper h2 {
  display: flex;
  align-items: center;
  gap: 50px;
}
.Wrapper h2 span {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.Wrapper button {
  background-color: #f1d2a9;
  border-radius: 20px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  padding: 15px 20px;
  border: 1px solid #f1d2a9;
}
.cards {
  padding: 2%;
  margin: 0 auto;
  text-align: center;
}
.cards a {
  text-decoration: none;
}

.icon {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 900;
  margin-left: auto;
}

.text {
  background-color: rgba(42, 125, 46, 0.274);
  color: white;
  text-align: center;
  padding: 4% 0 4%;
  margin-top: 30%;
  border-radius: 20px;
}
.text h3 {
  font-family: "Montserrat";
  font-size: 27px;
  font-weight: 600;
  letter-spacing: -0.02em;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 160%;
}
.text p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 160%;
  margin: 0;
}

@media screen and (max-width: 1150px) {
  .Wrapper h2 span {
    font-size: 35px;
    width: 100%;
  }
}

@media screen and (max-width: 1110px) {
  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text {
    padding: 5% 0 3%;
  }
}

@media screen and (max-width: 820px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    justify-content: center;
  }

  .Wrapper h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Wrapper h2 span {
    font-size: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .card {
    margin: 1% auto;
  }
}
