.h2Text {
  font-size: 30px;
  text-align: center;
  margin: 5% 0;
}

.modal i {
  font-size: 80px;
  border: 2px solid white;
  border-radius: 50%;
  color: white;
}
.modal {
  text-align: center;
}

.icon {
  background-color: #0b3d2c;
  padding: 4%;
}
.modal button {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 5%;
  padding: 10px 0px;
  font-size: 25px;
  background-color: #0b3d2c;
  border: 2px solid #0b3d2c;
  border-radius: 10px;
  cursor: pointer;
  color: white;
}

.Box {
  border-radius: 20px;
  overflow: hidden;
  border: none !important;
}
