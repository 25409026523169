.Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* width: 100%; */
  padding: 5% 2%;
}
.subtext {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  margin: 0;
  width: 90%;
}
.infors {
  width: 100%;
}

.texts h2 {
  font-family: "Montserrat";
  font-size: 35px;
  width: 80%;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #0b3d2c;
}
.infors ul li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0b3d2c;
  color: white;
  border-radius: 50%;
  font-size: 30px;
}

.infors ul li p {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0em;
}
.infors button {
  background-color: #2a7d2e;
  color: white;
  padding: 15px 25px;
  border-radius: 20px;
  font-family: "Montserrat";
  font-size: 16px;
  border: 1px solid #2a7d2e;
  font-weight: 600;
  letter-spacing: 0em;
  margin-top: 5%;
}
.cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background-color: #fcfaf7;
  padding: 3%;
}
.card {
  background-color: transparent;
  padding: 3%;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  transition: 0.65s;
  width: 33.333%;
}
.card:hover {
  background-color: #0b3d2c;
  color: white;
}
.card:hover h3 {
  color: white;
}
.card:hover a {
  color: white;
}
.card .icon {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  margin: 0 auto;
  box-shadow: 0px 7px 60px 0px #00000012;
}
.card h3 {
  font-family: "Montserrat";
  color: #0b3d2c;
  margin: 10% 0 5%;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.02em;
  transition: 0.65s;
}
.card p {
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
}
.card a {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  color: black;
  transition: 0.65s;
}
.card a span {
  font-weight: 600;
}

@media screen and (max-width: 1190px) {
  .infors {
    width: auto !important;
  }
  .Wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}
@media screen and (max-width: 890px) {
  .infors {
    width: 100%;
  }
  .texts {
    width: 100%;
  }
  .subtext {
    width: 100%;
  }
  .cards {
    display: flex;
    flex-direction: column;
  }
  .card {
    width: 95% !important;
  }
}

@media screen and (max-width: 510px) {
  .Wrapper h2 {
    font-size: 25px;
    width: 98% !important;
  }
  .icon {
    width: 60px;
  }
  .subtext {
    width: 100% !important;
  }
  .infors {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95% !important;
  }

  .infors ul li p {
    font-size: 15px;
    width: 100%;
  }
}
