.Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5% 0% 0%;
  gap: 30px;
}
.comments ul {
  display: flex;
  align-items: center;
  gap: 30px;
}
.comments ul li {
  border: 1px solid #2a7d2e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.65s;
}
.comments ul li:hover {
  background-color: #2a7d2e;
  color: white;
}
.comments ul li a {
  font-size: 20px;
}

.comments {
  width: 55%;
  display: flex !important;
  flex-direction: column !important;
  gap: 30px;
}

.boxComment {
  background-color: #fcfaf7;
  padding: 5%;
  display: flex;
  align-items: center;
  gap: 30px;
  border-radius: 20px;
}

.mainImg {
  border-radius: 20px;
  width: 450px !important;
}
.boxComment h2 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.User {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 4% 0 3%;
}
.User img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.infors p {
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}
.User h3 {
  color: black;
}

.categories {
  background-color: #0b3d2c1a;
  padding: 5%;
  border-radius: 20px;
  color: #0b3d2c;
}
.categories a {
  color: black;
}

.categories h2 {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.02em;
  border-bottom: 2px solid #0b3d2c;
  padding-bottom: 2%;
  margin: 0% 0 5%;
}
.categories ul li divq {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categories ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.categories ul li {
  cursor: pointer;
}
.categories ul li:hover div {
  width: 100%;
}
.categories ul li div {
  width: 10%;
  height: 3px;
  margin: 3% 0 0;
  background-color: #0b3d2c;
  transition: 0.65s;
}
.categories ul li divq i {
  font-size: 30px;
}
.contactUs {
  background: url(../.././../assets/BlogStandart/Frame.png), #0b3d2c;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 10% 5% 35%;
  color: white;
  margin-top: 5%;
  border-radius: 20px;
}
.contactUs h2 {
  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.contactUs p {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}
.Wrapper a {
  text-decoration: none !important;
}
.contactUs button {
  background-color: #2a7d2e;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  border: 1px solid #2a7d2e;
  cursor: pointer;
}
@media screen and (max-width: 1210px) {
  .Wrapper {
    padding: 5% 2% !important;
  }
}

@media screen and (max-width: 995px) {
  .mainImg {
    width: 200px !important;
  }
  .contactUs h2 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 860px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .comments {
    width: 100%;
  }

  .boxComment img {
    width: 55% !important;
  }
}
@media screen and (max-width: 575px) {
  .boxComment {
    display: flex;
    flex-direction: column;
  }
  .boxComment img {
    width: 100% !important;
    height: 100% !important;
  }
}
