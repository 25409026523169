.Wrapper {
  display: flex;
  align-items: flex-end;
  gap: 90px;
  justify-content: space-between;
  padding: 2%;
  margin: 5% 0;
}
.img img {
  max-width: 300px;
  width: 100% !important;
  border-radius: 20px;
}
.img img:nth-child(2) {
  margin-top: 5%;
}
.img {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
}
.texts {
  width: 45% !important;
}
.subtext {
  display: flex;
  align-items: center;
  gap: 10px;
}
.subtext span {
  color: #2a7d2e;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.Wrapper h2 {
  font-family: "Montserrat";
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.card p {
  color: #2a7d2e;
  font-size: 35px;
  font-weight: 600;
}
.longText {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 29.6px;
}
.cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.card {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 20px;
  background-color: white;
  padding: 1% 4%;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.card div {
  width: 65%;
  height: 10px;
  background-color: #2a7d2e;
}
.card h3 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

@media screen and (max-width: 1320px) {
  .Wrapper h2 {
    font-size: 35px;
  }
  .img img {
    max-width: 200px !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 1000px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .img img {
    max-width: 300px !important;
  }
  .texts {
    width: 90% !important;
  }
}
@media screen and (max-width: 675px) {
  .img img {
    max-width: 220px !important;
  }
}
@media screen and (max-width: 500px) {
  .Wrapper h2 {
    font-size: 25px;
  }
  .img {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin: 0 !important;
    flex-direction: column;
    gap: 20px !important;
  }
  .img img {
    max-width: 300px !important;
  }
  .card h3 {
    font-size: 18px;
    text-align: end;
  }
}

@media screen and (max-width: 380px) {
  .Wrapper h2 {
    font-size: 30px;
  }
  .Wrapper {
    padding: 0 !important;
  }
}
