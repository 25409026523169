.WrapperBack {
  background-image: url(../../../assets/Services/back.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 0;
}
.Wrapper {
  text-align: center;
  color: white;
}
.Wrapper p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0;
  padding: 0;
}
.Wrapper p span {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.Wrapper h2 {
  font-size: 45px;
}
.cards {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
  margin: 2% 0;
  padding: 2%;
}
.card {
  width: 27%;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
  padding: 2%;
  transition: 0.65s;
  border: 1px solid transparent;
}
.card:hover {
  background-color: #0b3d2c;
  color: white !important;
  border: 1px solid white;
  transform: scale(1.05);
}
.card:hover h2 {
  color: white;
}
.card h2 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin: 5% 0;
  transition: 0.65s;
}
.card .img img {
  border-radius: 20px;
  width: 100%;
  height: 200px;
}
.img {
  position: relative;
}
.cards a {
  text-decoration: none;
}
.icon {
  position: absolute;
  background-color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 60px rgba(52, 136, 5, 0.5);
  left: 80%;
  top: -15%;
}
.icon ion-icon {
  color: white;
  background-color: #2a7d2e;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 4%;
}

@media screen and (max-width: 1075px) {
  .card {
    width: 40%;
  }
}
@media screen and (max-width: 700px) {
  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .card {
    width: 60%;
  }
  .Wrapper h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 460px) {
  .card {
    width: 95%;
  }
  .texts h2 {
    width: 60%;
  }
  .icon {
    left: 70%;
  }
}
