.Wrapper h2 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.Wrapper {
  padding: 3% 2%;
}
.Wrapper a {
  text-decoration: none;
}
.card:hover .bottom {
  margin-top: 275px !important;
}
.bottom p {
  margin: 3% 0;
}
.bottom {
  background-color: #2a7d2ee5;
  color: white;
  border-radius: 20px;
  transition: 0.65s;
  margin-top: 120% !important ;
  padding: 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottom h3 {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.bottom p {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}

.icon i {
  width: 40px;
  height: 40px;
  font-size: 25px;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  color: #2a7d2ee5;
  border-radius: 50%;
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 500px) {
  .bottom {
    margin-top: 200%;
  }
  .card:hover .bottom {
    margin-top: 280px !important;
  }
  .card {
    width: 99%;
  }
  .bottom h3 {
    font-size: 18px;
  }
  .Wrapper h2 {
    font-size: 25px;
  }
  .icon {
    font-size: 20px;
  }
}
