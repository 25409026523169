.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 0 2%;
}
.form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.form {
  width: 50%;
  background-color: white;
  box-shadow: 0px 7px 60px 0px #00000012;
  padding: 6%;
  border-radius: 20px;
}
.form form {
  width: 100%;
}
.form input::placeholder {
  /* color: #0b3d2c !important; */
  font-weight: 500;
  font-family: "Montserrat";
}
.map iframe {
  width: 100%;
  height: 350px;
}
.map {
  width: 50%;
}
.form input {
  border: none;
  font-size: 15px;
  border-bottom: 1px solid #0b3d2c;
  outline: none;
  padding-bottom: 1%;
  background-color: transparent !important;
}
.form textarea {
  border: none;
  border-bottom: 1px solid #0b3d2c;
  font-size: 15px;
  outline: none;
}

.form textarea::placeholder {
  font-weight: 500;
  font-family: "Montserrat";
}

.form button {
  background-color: #0b3d2c;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border: 1px solid #0b3d2c;
  padding: 10px 0px;
  border-radius: 10px;
  cursor: pointer;
}
.map h2 {
  color: #0b3d2c;
  font-family: "Montserrat";
  margin-bottom: 5%;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 1050px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .map {
    width: 100%;
  }
  .form {
    width: 85%;
  }
  .map iframe {
    height: 500px;
  }
}
@media screen and (max-width: 500px) {
  .Wrapper h2 {
    font-size: 25px;
  }
  .map iframe {
    height: 300px;
  }
}
