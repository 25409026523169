.Wrapper {
  border: 1px solid #fcfaf7;
  background-color: #fcfaf7;
  width: 60%;
  border-radius: 20px;
  margin: 5% auto;
  padding: 5%;
}
.Wrapper div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.Wrapper h3 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 27px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: 5%;
}
.Wrapper div input {
  width: 100% !important;
}
.Wrapper form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.Wrapper input {
  padding: 15px 0px 15px 15px;
  border: 1px solid #0b3d2c1a;
  border-radius: 10px;
  outline: none;
}
.Wrapper input::placeholder {
  color: black;
  font-family: "Montserrat";
  font-weight: 500;
}
.Wrapper textarea::placeholder {
  color: black;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
}
.Wrapper textarea {
  outline: none;
  border: 1px solid #0b3d2c1a;
  border-radius: 10px;
  padding: 10px;
}

.Wrapper button {
  background-color: #0b3d2c;
  color: white;
  padding: 20px 0;
  font-size: 20px;
  font-weight: 600;
  border: 1px solid #0b3d2c;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .Wrapper {
    width: 85%;
  }
}
