.Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  padding: 5% 2%;
  position: relative;
}
.Wrapper a {
  text-decoration: none;
  color: black;
}
.moreComment {
  width: 70%;
}
.moreComment h2 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.subtext {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #2f2f2f;
}
.categories {
  background-color: #0b3d2c1a;
  padding: 5%;
  border-radius: 20px;
  color: #0b3d2c;
}

.categories h2 {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.02em;
  border-bottom: 2px solid #0b3d2c;
  padding-bottom: 2%;
  margin: 0% 0 5%;
}
.categories ul li divq {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categories ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.categories ul li {
  cursor: pointer;
}
.categories ul li:hover div {
  width: 100%;
}
.categories ul li div {
  width: 10%;
  height: 3px;
  margin: 3% 0 0;
  background-color: #0b3d2c;
  transition: 0.65s;
}
.categories ul li divq i {
  font-size: 30px;
}
.contactUs {
  background: url(../.././../assets/BlogStandart/Frame.png), #0b3d2c;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 10% 5% 35%;
  color: white;
  margin-top: 5%;
  border-radius: 20px;
}
.contactUs h2 {
  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.contactUs p {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}

.contactUs button {
  background-color: #2a7d2e;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  border: 1px solid #2a7d2e;
}
.popular {
  margin-top: 5%;
}
.mainImg {
  width: 700px;
  height: 400px;
  border-radius: 20px;
}
.user {
  display: flex;
  align-items: center;
  gap: 30px;
  border-bottom: 1px solid grey;
  margin: 0% 0 5% 0;
  padding-bottom: 3%;
}
.user img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.user h3 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.02em;
}
.user p {
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}
.pluss {
  position: sticky !important;
  bottom: -100%;
  top: -0%;
}

@media screen and (max-width: 1085px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .moreComment {
    width: 100%;
  }
}

@media screen and (max-width: 725px) {
  .mainImg {
    width: 100%;
  }
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 470px) {
  .user {
    display: flex;
    flex-direction: column;
  }
}
