.Ul {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 4%;
}

.Ul .li {
  width: 100%;
}
.Ul ion-icon {
  color: #0b3d2c;
  font-size: 25px;
}
.Ul p {
  font-size: 16px;
}
.Ul a {
  text-decoration: none;
}
.Ul h3 {
  color: #0b3d2c;
  font-size: 20px;
  font-weight: 600;
}

.icon {
  background-color: #f1d2a9;
  border-radius: 50px;
  width: 45px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.texts {
  margin-left: 2%;
  width: 100%;
}
