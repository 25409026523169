.Wrapper {
  width: 100%;
  text-align: center;
  padding: 4% 2%;
}
.Wrapper h2 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.Wrapper p {
  color: #2f2f2f;
}
.subtext {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}
.mainImg {
  width: 70%;
  height: 500px;
  border-radius: 20px;
}

.mainH3 {
  font-family: "Montserrat";
  font-size: 40px;
  padding: 5% 0 0%;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #0b3d2c;
}
.mainList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.mainList li {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
.stages ul li {
  width: 33.3333% !important;
}

.mainList li i {
  background-color: #2a7d2e;
  margin: 0;
  padding: 0;
  width: 25px;
  height: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
}
.mainList li h3 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
}
.miniImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 2% 0;
}
.miniImgs img {
  width: 70%;
  height: 500px;
  border-radius: 20px;
}
.stages {
  background-image: url(../../../assets/Details/Line.png);
  background-repeat: no-repeat;
  background-position: center;
}
.stages ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 5% 0;
}
.stages .ImgStage {
  background-color: white;
  box-shadow: 0px 10px 60px 0px #0000001a;
  border-radius: 50%;
  position: relative;
  width: 70px;
  height: 70px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stages img {
  width: 50px !important;
}
.number {
  position: absolute;
  right: -15%;
  background-color: #2a7d2e;
  color: white !important;
  border-radius: 50%;
  font-weight: 600;
  font-size: 18px;
  top: -40%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.stages ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stages h3 {
  font-family: "Montserrat";
  font-size: 20px;
  padding: 5% 0 0%;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #0b3d2c;
}
.stages p {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}

@media screen and (max-width: 750px) {
  .mainImg {
    width: 80%;
  }
  .stages ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .stages ul li {
  width: 90% !important;
  }
  .subtext {
    width: 90%;
    margin: 2% auto 4%;
  }
  .miniImgs img {
    width: 45% !important;
  }
}
@media screen and (max-width: 520px) {
  .Wrapper h2 {
    font-size: 30px;
  }
  .mainH3 {
    font-size: 20px;
  }
  .mainImg {
    width: 90%;
    height: 300px;
  }
  .subtext {
    font-size: 18px;
  }
  .mainList h3 {
    font-size: 18px !important;
  }
  .mainList {
    padding: 1% 0 5%;
  }
  .miniImgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .miniImgs img {
    width: 90% !important;
    height: 300px;
  }
  .mainList i {
    width: 30px !important;
    height: 30px !important;
  }
}
