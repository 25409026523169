.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 2%;
}
.mainImg {
  max-width: 700px;
  width: 100%;
  border-radius: 20px;
}
.texts {
  width: 100%;
}
.subtext {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #2a7d2e;
  display: flex;
  align-items: center;
  gap: 10px;
}
.texts h2 {
  font-family: "Montserrat";
  font-size: 35px;
  color: #0b3d2c;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.02em;
}
.longText {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}
.expi {
  display: flex;
  justify-content: space-between;
}
.expi ul li {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  background-color: #0b3d2c;
}
.icon ion-icon {
  width: 20px !important;
  height: 20px;
  color: white;
}
.expi ul {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.expi ul li p {
  margin: 0;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  width: 80%;
}
.expi ul li button {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: #2a7d2e;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #2a7d2e;
}
.year {
  background-color: #fcfaf7;
  text-align: center;
  border: 1px solid #0b3d2c;
  border-end-end-radius: 49%;
  padding: 5% 3%;
  border-end-start-radius: 49%;
  border-top: 5px solid #0b3d2c;
  width: 30%;
}

.year h3 {
  font-size: 30px;
  color: #2a7d2e;
  font-weight: 600;
  padding-bottom: 15%;
}
.year p {
  font-size: 25px;
  width: 80%;
  margin: 5% auto;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.02em;
}
.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #0b3d2c;
}
.icons ion-icon {
  font-size: 40px;
  color: white;
}

@media screen and (max-width: 1170px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 50px;
  }
  .mainImg {
    width: 50%;
  }
  .texts .subtext {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .expi {
    margin-top: 5%;
  }
}
@media screen and (max-width: 770px) {
  .mainImg {
    width: 80%;
  }
  .expi {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 520px) {
  .year {
    width: 80%;
  }
  .Wrapper h2 {
    font-size: 25px;
    line-height: 120%;
  }
  .Wrapper p {
    font-size: 15px;
  }
  .mainImg {
    width: 95%;
  }
  .expi ul li p {
    font-size: 15px;
    text-align: start;
  }
  .expi ul li button {
    margin: 0 auto;
  }
}
