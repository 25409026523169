.Wrapper {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  margin: 5% 0;
  overflow: hidden;
  border: 1px solid #0b3d2c1a;
}

.card {
  padding: 5%;
  background-color: #fcfaf7;
  text-align: center;
  width: 33.3333%;
}
.card p {
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}
.card a {
  text-decoration: none;
}
.card h3 {
  color: #0b3d2c;
  font-family: "Montserrat";
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.card a p {
  color: #0b3d2c;
  font-family: "Montserrat";
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.card:nth-child(2) {
  border-left: 1px solid #0b3d2c1a;
  border-right: 1px solid #0b3d2c1a;
}

.Wrappe {
  padding: 0 2%;
}

@media screen and (max-width: 1050px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .card {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .card img {
    width: 200px;
  }
}
