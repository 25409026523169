.WrapperBack {
  background-color: #ffffff;
  padding: 5% 0;
}
.Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  gap: 30px;
}
.Wrapper p {
  margin: 0;
}
.texts {
  width: 60%;
}
.texts p {
  color: #2a7d2e;
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 600;
  font-size: 22px;
}
.texts h2 {
  font-family: "Montserrat";
  font-size: 35px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.card {
  background: #fcfaf7;
  box-shadow: 0px 10px 60px rgba(104, 104, 104, 0.2);
  border-radius: 20px;
  padding: 2%;
}
.card .icons {
  display: flex;
  align-items: center;
  gap: 10px !important;
}
.icons i {
  font-size: 30px;
  color: #ff9900;
}
.card h3 {
  color: #2a7d2e;
  font-family: "Montserrat";
  font-size: 45px;
  margin: 8% 0;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.card p {
  color: #2f2f2f;
  font-weight: 500;
  font-size: 20px;
}
.more {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.more p {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}
.more a {
  color: #2a7d2e;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.Wrapper {
  padding: 2%;
}
@media screen and (max-width: 1270px) {
  .texts h2 {
    font-size: 35px;
  }
}

@media screen and (max-width: 990px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .texts {
    width: 100%;
  }
  .more {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .Wrapper {
    text-align: center;
  }
  .texts p {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card {
    padding: 3% !important;
    margin: 0 auto;
  }
  .card h3 {
    font-size: 25px;
  }
  .card p {
    font-size: 15px;
  }
  .icons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icons i {
    font-size: 20px;
  }
  .more a {
    margin: 0 auto;
  }
  .texts h2 {
    font-size: 25px;
    width: 100%;
    padding: 5% 0 2%;
    width: 100% !important;
  }
}
