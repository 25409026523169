.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 5% 2%;
}
.imgDiv {
  width: 80%;
}
.imgDiv img {
  width: 100%;
  height: 700px;
  border-radius: 20px;
}
.texts {
  width: 100% !important;
}
.Wrapper h2 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
.card {
  background-color: #fcfaf7;
  padding: 10%;
  border-radius: 20px;
  /* width: 300px !important; */
  transition: 0.65s;
  cursor: pointer;
}
.card:hover {
  background-color: #0b3d2c;
  color: white !important;
  transform: scale(1.03);
}
.card:hover h3 {
  color: white;
}
.card:hover p {
  color: white;
}
.card:hover .icon {
  box-shadow: 0px 10px 60px 0px #ffffffcc;
}
.cards {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 2% 0%;
  gap: 30px;
  width: 85%;
}
.left,
.right {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.right {
  margin-top: 8%;
}
.icon {
  width: 75px;
  box-shadow: 0px 10px 60px 0px #0000001a;
  height: 75px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.65s;
}
.card h3 {
  color: #0b3d2c;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.02em;
  margin: 5% 0;
  transition: 0.65s;
}
.card p {
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  transition: 0.65s;
}
.left {
  width: 50% !important;
}
.right {
  width: 50% !important;
}

@media screen and (max-width: 1130px) {
  .imgDiv {
    width: 70%;
  }
}
@media screen and (max-width: 920px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cards {
    margin: 0 auto;
    width: 100%;
  }
}
@media screen and (max-width: 665px) {
  .imgDiv {
    width: 90%;
  }
  .imgDiv img {
    width: 100% !important;
    height: 100% !important;
  }
}

@media screen and (max-width: 500px) {
  .Wrapper h2 {
    font-size: 25px;
  }
  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
  }
  .card {
    width: 80% !important;
  }
  .left {
    width: 100% !important;
  }
  .right {
    width: 100% !important;
  }
}
